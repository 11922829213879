<template>
  <div class="serveManage">
    <ds-header title="活动报名列表"></ds-header>
    <a-form layout="inline">
      <a-form-item label="标题：">
        <a-input v-model.trim="searchForm.activityTitle" placeholder="请输入活动标题" class="inputClass" />
      </a-form-item>
      <a-form-item label="活动方式：">
        <a-select default-value="" v-model="searchForm.activityWay" style="width: 200px">
          <a-select-option value="">
            全部
          </a-select-option>
          <a-select-option value="1">
            线下
          </a-select-option>
          <a-select-option value="2">
            商业活动
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="状态：">
        <a-select default-value="" v-model="searchForm.activityStatus" style="width: 200px">
          <a-select-option value="">
            全部
          </a-select-option>
          <a-select-option value="0">
            禁用
          </a-select-option>
          <a-select-option value="1">
            启用
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="活动时间：">
        <a-range-picker
            show-time
            v-model="searchForm.activityTime"
        >
        </a-range-picker>
      </a-form-item>
      <a-form-item label="可见项目">
        <a-tree-select
          v-model="searchForm.projectIds"
          style="width: 200px"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          placeholder="请选择所属项目"
          allow-clear
          multiple
          tree-default-expand-all
        >
          <a-tree-select-node :disabled="item.disabled" :value="item.id" :title="item.project_name" v-for="item in projectList" :key="item.id">
            <a-tree-select-node :key="arr.id" :value="arr.id" :title="arr.project_name" v-for="arr in item.child">
            </a-tree-select-node>
          </a-tree-select-node>
        </a-tree-select>
      </a-form-item>
      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search" />查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
    </a-form>
    <div style="padding-top: 15px">
      <a-button type="primary" @click="addActivity()"> <a-icon type="plus" /> 新建活动</a-button>
    </div>
    <div style="padding-top: 15px">
      <a-table
          :rowKey="(record, index) => index"
          :columns="table.columns"
          :data-source="table.dataInfo"
          :pagination="table.pagination.total ? table.pagination : false"
          @change="pageChange"
          bordered
          :scroll="{ x: 1500 }"
      >
        <span slot="action" slot-scope="text, record" class="action">
          <a-button class="btnClass" type="link" @click="view(record)"> 查看 </a-button>
          <a-button class="btnClass" type="link" @click="edit(record)" v-show="record.activityState === 0"> 编辑 </a-button>
          <a-button class="btnClass" type="link" @click="userScanCode(record)"
                    v-if="record.verifyType === 1 && record.showPlatform == 0 && record.whetherOff == 1 && record.whetherRegistration == 1
                ">
            用户主扫二维码
          </a-button>
          <a-button class="btnClass" type="link" @click="startUsingAndEnable(record.id, 0)" v-if="record.activityState === 1">禁用 </a-button>
          <a-button class="btnClass" type="link" @click="startUsingAndEnable(record.id, 1)" v-if="record.activityState === 0"> 启用 </a-button>
          <a-button class="btnClass" type="link" @click="deleteActivityById(record)" > 删除 </a-button>
          <a-button class="btnClass" type="link" @click="showActivityQrCode(record)">活动二维码</a-button>
        </span>
        <span slot="export" slot-scope="text, record" class="export">
          <a-button class="btnClass" type="link" @click="downLoad(record.id)"> 导出报名信息 </a-button>
        </span>
      </a-table>
    </div>
    <a-modal v-model="isShowQrcode" title="主扫二维码" :footer="null">
      <div class="qrCodeClass">
        <div id="qrcode" ref="qrcode"></div>
        <div style="margin-top: 15px">
          <a-button type="primary" @click="downLoadCode">下载</a-button>
        </div>
      </div>
    </a-modal>
    <a-modal v-model="visibleActivityQrCode" width="30%" title="活动二维码" :footer="null">
      <div class="qrCodeClass">
        <div class="codeItem">
          <div class="codeTitle">
            i友app
          </div>
          <div id="activityQrcode" ref="activityQrcode"></div>
          <div style="margin-top: 15px">
            <a-button type="primary" @click="downLoadActivityQrCode('activityQrcode')">下载</a-button>
          </div>
        </div>
        <div class="codeItem">
          <div class="codeTitle">
            微信
          </div>
          <div id="wxQrcode" ref="wxQrcode"></div>
          <div style="margin-top: 15px">
            <a-button type="primary" @click="downLoadActivityQrCode('wxQrcode')">下载</a-button>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ConfirmBoxModule from "@/components/confirmBoxModule";
import DSTable from "@/components/DSComponents/DSTable";
import { getActivityList,toStartUsingAndEnable, removeActivityById, getProjects} from "@/api/activityRegistration";
import moment from "moment";
import QRCode from 'qrcodejs2'
export default {
  name: "activityRegistration",
  components: {
    DSTable,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.load.loading,
      projectList: (state) => {
        return state.common.projects.length && state.common.projects.map(item => {
          return {
            ...item,
            disabled: !!(item.child)
          }
        })
      }
    }),
  },
  data() {
    return {
      // 是否展示活动报名二维码
      visibleActivityQrCode: false,
      dateFormat: 'YYYY/MM/DD',
      // 检索
      searchForm: {
        // 活动标题
        activityTitle: "",
        // 活动方式
        activityWay: "",
        // 活动状态
        activityStatus: '',
        // 活动时间
        activityTime: [],
        // 活动可见项目
        projectIds: undefined
      },
      // 是否展示二维码
      isShowQrcode: false,
      title: "",
      id: "",
      table: {
        dataInfo: [],
        columns: [
          {
            title: "活动id",
            dataIndex: "id",
            key: "id",
          },
          {
            title: "标题",
            dataIndex: "title",
            key: "title",
          },
          {
            title: "状态",
            dataIndex: "activityState",
            customRender: (text) =>  {
              if (text === 1) {
                return '启用'
              } else if (text === 0) {
                return '禁用'
              }
            }
          },
          {
            title: "活动方式",
            dataIndex: "activityMode",
            customRender: (text) => {
              if (text === 1) {
                return "线下"
              } else if (text === 2) {
                return '商业活动'
              } else {
                return '无'
              }
            }
          },
          {
            title: "报名人数",
            dataIndex: "number",
            key: "number",
          },
          {
            title: "核销人数",
            dataIndex: "writeOffNumber",
            key: "writeOffNumber",
          },
          {
            title: "可见项目",
            dataIndex: "projectList",
            customRender(text) {
              let projects = []
              if (text !== null && text.length > 0) {
               text.forEach((item)=> {
                 projects.push(item.projectName)
                })
                return projects
              } else {
                return '-'
              }
            }
          },
          {
            title: "活动时间",
            dataIndex: "gmtCreate",
            customRender(text, record, index) {
              return record.startTime + '～' + record.endTime;
            },
          },
          {
            title: "链接地址（报名详情页）",
            dataIndex: "activityUrl",
            key: "activityUrl",
          },
          {
            title: "更新时间",
            dataIndex: "gmtModified",
            key: "gmtModified",
            customRender(text) {
              return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "-";
            },
          },
          {
            title: "是否验证参与",
            dataIndex: "whetherOff",
            customRender(text) {
              if (text === 1) {
                return '是'
              } else {
                return '否'
              }
            }
          },
          {
            title: "验证方式",
            dataIndex: "verifyType",
            customRender(text, record) {
              if (record.whetherRegistration === 0 || record.whetherOff === 0) {
                return '-'
              } else {
                if (text === 1) {
                  return '用户主扫'
                } else {
                  return '用户被扫'
                }
              }
            }
          },
          {
            title: "操作",
            key: "action",
            width: "200px",
            className: "action-ql-list",
            scopedSlots: { customRender: "action" },
          },
          {
            title: "数据导出",
            className: "action-ql-list",
            scopedSlots: { customRender: "export" },
          },
        ],
        //分页数据
        pagination: {
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
    };
  },
  mounted() {
    this.searchFormList();
  },
  methods: {
    // 新建活动
    addActivity () {
      this.$router.push({
        path: "/activityRegistration/add",
        query: {
          type: "add",
        },
      });
    },
    // 处理接口参数
    searchFormList() {
      let data = {
        // 活动标题
        activityTitle:  this.searchForm.activityTitle,
        // 活动方式
        activityWay: this.searchForm.activityWay,
        // 活动状态
        activityStatus: this.searchForm.activityStatus,
        // 活动时间
        activityStart: this.searchForm.activityTime.length === 0 ? '' : moment(this.searchForm.activityTime[0]).format('YYYY-MM-DD HH:mm:ss'),
        activityEnd: this.searchForm.activityTime.length === 0 ? '' : moment(this.searchForm.activityTime[1]).format('YYYY-MM-DD HH:mm:ss'),
        // 活动可见项目
        projectIds: this.searchForm.projectIds,
      }
      this.getList(this.table.pagination.pageSize, this.table.pagination.current, data);
    },

    // 查看
    view(record) {
      let id = record.id;
      this.$router.push({
        path: "/activityRegistration/add",
        query: {
          id: id,
          type: "view",
        },
      });
    },

    // 获取列表
    async getList(size, page, data) {
      const res = await getActivityList(size, page, data);
      if (res.code === "200") {
        this.table.pagination.total = res.data.total;
        this.table.dataInfo = res.data.items;
      }
    },


    //查询
    search() {
      this.table.pagination.current = 1;
      this.searchFormList();
    },

    //重置
    resetSearchForm() {
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.searchForm.activityTitle = "";
      this.searchForm.activityWay = "";
      this.searchForm.activityStatus = ''
      this.searchForm.activityTime = ''
      this.searchForm.projectIds = []
      this.searchFormList();
    },

    //编辑
    edit(record) {
      this.id = record.id;
      this.$router.push({
        path: "/activityRegistration/add",
        query: {
          id: this.id,
          type: "edit",
        },
      });
    },
    // 展示二维码
    userScanCode (data) {
      this.isShowQrcode = true
      let code = {
        scanType: "share",
        scanValue: `${process.env.NODE_ENV === 'production' ? 'https://yuanqu.innoecos.cn/apps/appcv/registrationConfirm?id='+data.id :
            'https://yuanqu-sit.innoecos.cn/apps/appcv/registrationConfirm?id='+data.id
        }`,
      }
      setTimeout (()=> {
        this.$refs.qrcode.innerHTML = ''
        new QRCode(this.$refs.qrcode, {
          width: 200, // 二维码宽度，单位像素
          height: 200, // 二维码高度，单位像素
          text: JSON.stringify(code), // 生成二维码的链接
          correctLevel: QRCode.CorrectLevel.H
        })
      },100)
    },
    /**
     * 展示活动详情二维码
     */
    showActivityQrCode(row) {
      this.visibleActivityQrCode = true
      const { activityUrl, id } = row
      let code = activityUrl
      let wxCode = ''
      if(process.env.NODE_ENV === 'production') {
        wxCode = `https://yuanqu.innoecos.cn/apps/appcv/activityShare?id=${id}&isAndroid=iOS&version=iOS&form=App`
      } else {
        wxCode = `https://yuanqu-sit.innoecos.cn/apps/appcv/activityShare?id=${id}&isAndroid=iOS&version=iOS&form=App`
      }
      setTimeout (()=> {
        this.$refs.activityQrcode.innerHTML = ''
        new QRCode(this.$refs.activityQrcode, {
          width: 200, // 二维码宽度，单位像素
          height: 200, // 二维码高度，单位像素
          text: code, // 生成二维码的链接
          correctLevel: QRCode.CorrectLevel.H
        })
      },100)
      setTimeout (()=> {
        this.$refs.wxQrcode.innerHTML = ''
        new QRCode(this.$refs.wxQrcode, {
          width: 200, // 二维码宽度，单位像素
          height: 200, // 二维码高度，单位像素
          text: wxCode, // 生成二维码的链接
          correctLevel: QRCode.CorrectLevel.H
        })
      },100)
    },

    //分页操作
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.searchFormList();
    },
    // 禁用启用
    startUsingAndEnable(id, status) {
      let that = this
        this.$confirm({
          title: `确定要${status === 1 ? '启用' : '禁用'}该活动吗？`,
          onOk() {
            toStartUsingAndEnable(id, status).then(res => {
              if (res.code === '200') {
                that.$message.success('操作成功')
                that.searchFormList()
              } else {
                that.$message.error('操作失败')
              }
            })
          },
          onCancel() {},
        });
    },
    // 删除活动
    deleteActivityById(data) {
      let that = this
      if (data.activityStatu === 1) {
        this.$message.warning('活动正在启用状态，禁止删除！')
        return;
      }
      this.$confirm({
        title: `确定要删除该活动吗？`,
        onOk() {
          removeActivityById(data.id).then(res => {
            if (res.code === '200') {
              that.$message.success('操作成功')
              that.searchFormList()()
            } else  {
              that.$message.error('操作失败')
            }
          })
        },
        onCancel() {},
      });
    },
    // 下载二维码
    downLoadCode() {
      //找到canvas标签
      const myCanvas = document.getElementById('qrcode').getElementsByTagName('canvas')
      //创建一个a标签节点
      const a = document.createElement('a')
      //设置a标签的href属性（将canvas变成png图片）
      a.href = myCanvas[0].toDataURL('image/png').replace('image/png', 'image/octet-stream')
      //设置下载文件的名字
      a.download = '核销码.png'
      //点击
      a.click()
    },

    /**
     * 活动二维码下载
     */
    downLoadActivityQrCode(refCode) {
      //找到canvas标签
      const myCanvas = document.getElementById(refCode).getElementsByTagName('canvas')
      //创建一个a标签节点
      const a = document.createElement('a')
      //设置a标签的href属性（将canvas变成png图片）
      a.href = myCanvas[0].toDataURL('image/png').replace('image/png', 'image/octet-stream')
      //设置下载文件的名字
      a.download = `活动${refCode === 'activityQrcode' ? 'i友' : '微信'}二维码.png`
      //点击
      a.click()
    },
    // 导出报名信息
    downLoad (id) {
      window.open(
          `${location.origin}/api/dscloud-app-forum/web/activityInformation/activityUser/excel?activityId=${id}`
      )
    }
  },
};
</script>

<style lang="scss" scoped>
.btnClass {
  padding: 0px;
  padding-right: 5px;
}
.footerButton {
  margin-top: 10px;
  margin-right: 10px;
}
.action a {
  color: #1890ff;
  margin-right: 15px;
}
.action a:hover {
  color: #40a9ff;
}
.field {
  font-size: 12px;
  color: #333;
  display: inline-block;
  height: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 80px;
}
.inputClass {
  width: 200px;
}
.inputColor {
  color: #bdbdbd !important;
}
.qrCodeClass {
  display: flex;
  justify-content: space-around;
  align-items: center;
  .codeItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .codeTitle {
      margin-bottom: 15px;
      font-weight: 600;

    }
  }
}
</style>
