import { fetchApi } from "@/utils/axios";
import URL from "@/api/urlConfig"

const prefix = '/api/dscloud-app-forum'

const local = '/local'

// 获取所有项目
export const getProjects = () => {
    return fetchApi(URL.BINDING_PROJECT_LIST, null)
}

// 添加修改签到规则
export const signRule = (data) => {
    return fetchApi(URL.SIGN_RULE, data, 'POST', 'data')
}
// 获取所有人群
export const findAllCrowd = () => {
    return fetchApi(URL.GET_ALL_CROWD, 'null', 'get');
}

// 获取活动列表
export const getActivityList = (size, page, data) => {
    const params = {
        title: data.activityTitle,
        activityMode: data.activityWay,
        status:data.activityStatus,
        activityStartTime:data.activityStart,
        activityEndTime: data.activityEnd,
        projectIds: data.projectIds,
        paging: {
            page: page-1,
            size: size
        }
    }
    return fetchApi(URL.GET_ACTIVITY_LIST, params, 'POST', 'data', true)
}

// 禁用启用活动
export const toStartUsingAndEnable = (id, status) => {
    const data = {
        id: id,
        activityState: status
    }
    return fetchApi(URL.START_USING_AND_ENABLE_ACTIVITY, data, 'post', 'params')
}

// 删除活动
export const removeActivityById = (id) => {
    const data = {
        id: id
    }
    return fetchApi(URL.REMOVE_ACTIVITY_BY_ID, data, 'post', 'params')
}
// 新建活动
export const addActivity = (data) => {
    return fetchApi(prefix + '/web/activityInformation/insert', data, 'post', 'data')
}
// 修改活动
export const updateActivity = (data) => {
    return fetchApi(prefix + '/web/activityInformation/updateById', data, 'post', 'data')
}

// 根据id获取活动详情
export const findActivityById = (id) => {
    const data = {
        id: id
    }
    return fetchApi(prefix + '/web/activityInformation/findById', data, 'post', 'params')
}

// 查询积分规则信息
export const findPointsRuleInfo = ruleNo => fetchApi(URL.FIND_POINTS_RULE_INFO, { ruleNo });
